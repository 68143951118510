.home-base {
  width: 100%;
}

.home-hero-base {
  min-height: 100vh;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  position: relative;
}

.home-hero-leftContainer,
.home-hero-rightContainer {
  position: relative;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home-hero-leftContainer > h3 {
  margin-bottom: 40px;
  font-size: large;
  text-align: center;
}
.home-hero-leftContainer > img {
  margin-bottom: 25px;
  width: 50%;
  padding: 5px 5px;
}

.home-hero-leftContainer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  filter: blur(0.75px);
  height: 100%;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  padding-top: 20px;
  padding-bottom: 20px;
}

.aboutUs-title {
  text-align: center;
  font-size: 40px;
  /* margin: 20px 0 40px; */
}

.aboutUs-contentContainer {
  width: 60%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 40px;
  row-gap: 40px;
  justify-items: center;
  margin-bottom: 60px;
  padding-top: 60px;
}
.aboutUs-contentContainer > img {
  height: 300px;
}

.aboutUs-leftContainer,
.aboutUs-rightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aboutUs-leftContainer {
  text-align: left;
}

.aboutUs-contentTitle {
  margin-bottom: 10px;
}
.aboutUs-rightContainerMobileview {
  display: none;
}
.aboutUs-rightContainer>img{
   display: unset;
}
.aboutUs-para {
  font-size: 18px;
  line-height: 1.8rem;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .home-hero-base {
    flex-direction: column;
  }
  .home-hero-base > img {
    object-fit: cover;
  }

  .home-hero-leftContainer,
  .home-hero-rightContainer {
    width: 80%;
  }

  .home-hero-leftContainer > img {
    margin-top: 100px;
  }
  .home-hero-rightContainer > img {
    height: 400px;
    margin-bottom: 10px;
    order: -1;
  }
  .home-hero-leftContainer > h3 {
    display: flex;
  }
  .aboutUs-para {
    margin: 20px;
  }
  .aboutUs {
    flex-direction: column;
    width: 100%;
    align-content: center;
    justify-content: center;
  }

  .aboutUs-rightContainer {
    flex-direction: column;
    text-align: left;
  }
  .aboutUs-leftContainer {
    flex-direction: column;
    text-align: left;
  }

  .aboutUs-leftContainer > img {
    margin: 18px;
    width: 90%;
    height: 100%;
  }
  .aboutUs-rightContainer > img {
    display: none;
  }
  .aboutUs-rightContainerMobileview {
    display: unset;
  }

  .aboutUs-rightContainerMobileview >img{
    display: initial;
    margin: 18px;
    height: 100%;
    width: 90%;
  }

  .aboutUs-contentContainer {
    grid-template-columns: repeat(1, auto);
  }
  .aboutUs-contentContainer {
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .aboutUs-contentTitle {
    padding-right: 30px;
  }
}
