.locationsearch-resultBase {
  z-index: 10;
  position: absolute;
  margin: 0;
  padding: 0;
  max-height: 30vh;
  list-style-type: none;
  text-align: left;
  overflow-y: auto;
  background-color: #fefefe;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%);
}
.locationsearch-resultBase::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.locationsearch-resultBase::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

.locationsearch-listItem {
  padding: 12px 10px;
  width: 100%;
  cursor: pointer;
  background-color: #fefefe;
}

.locationsearch-listItem:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
