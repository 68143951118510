.selectbox-base {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  background: transparent;
  border: transparent;
  --sidebar-padding: 10px;
}
.selectbox-base::after {
  position: absolute;
  content: "›";
  padding-top: var(--sidebar-padding);
  font-size: 20px;
  transform: rotate(90deg);
  right: 0;
  z-index: 0;
  pointer-events: none;
}

.selectbox-base > select {
  appearance: none;
  width: 100%;
  height: 100%;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 12px 10px;
}

.selectbox-base > select > option:disabled {
  text-align: center;
  font-weight: 600;
  font-style: italic;
  background-color: #ccc;
}

.selectbox-base > select > option {
  background-color: #fff;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}
.selectbox-base > select > option:checked {
  background-color: var(--primary-color-light);
}

.selectbox-base > select:focus {
  outline: none;
  border-color: var(--primary-color);
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}
.selectbox-base > select:hover {
  background-color: var(--primary-color-light);
}
