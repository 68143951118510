.createMapCart-base {
  padding: 20px;
  background-color: #fff;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow-y: auto;
}

.createMapCart-base::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.createMapCart-base::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

.createmap-couponContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.createmap-inputRightChildContainer {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  flex: 1;
  margin-right: 2%;
  height: 45px;
}
.createmap-inputRightChildContainer > input {
  border: none;
  flex: 1;
  padding: 0;
  background-color: transparent;
  height: 100%;
}

.createmap-inputRightChildContainer:focus-within {
  outline: none;
  border-color: var(--primary-color);
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}
.createmap-inputRightChildContainer:hover {
  outline: none;
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}
.createmap-inputRightChild {
  cursor: pointer;
  font-weight: 900;
  color: red;
  border-radius: 3px;
  width: 25px;
  text-align: center;
  font-size: 14px;
}

.createmap-inputRightChild-appliedCouponinfo {
  font-size: 19px;
  color: green;
  padding-left: 5px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .createMapCart-base {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
