.stripBase{
    width: 500px;
    height: 500px;
    background-color: #fff;
    padding: 30px
}
.stripBase iframe{
    height: 250px !important;
    width: 300px !important;
}
.submitBtn{
    width: 200px;
    height: 50px;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-size: 20px;
    font-family: inherit;
    font-weight: 600;
    border: none;
    cursor: pointer;
}