.loader-radar {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--primary-color);
  z-index: 1;
  background-color: var(--primary-color-light);
  /* 95, 48, 226 */
  /* background-image: repeating-radial-gradient(
    transparent 0,
    transparent 0.39em,
    rgba(95, 48, 226, 0.5) 0.45em,
    rgba(95, 48, 226, 0.5) 0.5em
  ); */
  background-image: repeating-radial-gradient(
    transparent 0,
    transparent 0.39em,
    rgba(95, 48, 226, 0.5) 0.59em,
    rgba(95, 48, 226, 0.5) 0.54em
  );
}

.loader-radar:before,
.loader-radar:after {
  content: "";
  position: absolute;
  display: block;
}
.loader-radar:before {
  z-index: 0;
  width: 42px;
  height: 42px;
  width: var(--scanWidth);
  height: var(--scanWidth);
  top: 50%;
  left: 50%;
  transform-origin: left top;
  border-left: 0.1em solid #19a6cf;
  background-image: linear-gradient(
    to right,
    rgba(95, 48, 226, 0.55) 0%,
    transparent 85%
  );
  animation: radar 2s infinite linear;
}
/* .loader-radar:after {
  left: 62%;
  top: 34%;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: currentColor;
  animation: radarDotGlow 2s infinite linear,
    radarDotMove 15s infinite cubic-bezier(0.5, -0.35, 0.5, 1.35);
} */

.loader-radar-dot {
  position: absolute;
  left: 62%;
  top: 34%;
  /* width: 2px;
  height: 2px; */
  border-radius: 50%;
  background-color: var(--primary-color);
  animation: radarDotGlow 2s infinite linear,
    radarDotMove 15s infinite cubic-bezier(0.5, -0.35, 0.5, 1.35);
}

@keyframes radar {
  to {
    transform: rotate(359deg);
  }
}

@keyframes radarDotGlow {
  0%,
  59.9%,
  87%,
  100% {
    color: rgba(31, 25, 207, 0.33);
    box-shadow: 0 0 0.1em 0.05em currentColor;
  }
  62% {
    color: var(--primary-color);
    box-shadow: 0 0 0.1em 0.1em currentColor;
  }
}

@keyframes radarDotMove {
  0% {
    top: 0%;
    left: 85%;
  }
  40% {
    top: 30%;
    left: 70%;
  }
  100% {
    top: 50%;
    left: 50%;
  }
}

/* .loader-radar {
  position: relative;
  width: 4em;
  height: 4em;
  top: 33%;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #0e5f76;
  z-index: 1;
  background-color: rgba(8, 61, 86, 0.2);
  background-image: repeating-radial-gradient(
    transparent 0,
    transparent 0.39em,
    rgba(8, 61, 86, 0.5) 0.45em,
    rgba(8, 61, 86, 0.5) 0.5em
  );
}

.loader-radar:before,
.loader-radar:after {
  content: "";
  position: absolute;
  display: block;
}
.loader-radar:before {
  z-index: 0;
  width: 2.5em;
  height: 2em;
  top: 50%;
  left: 50%;
  transform-origin: left top;
  border-left: 0.1em solid #19a6cf;
  background-image: linear-gradient(
    to right,
    rgba(8, 61, 86, 0.75) 0%,
    transparent 85%
  );
  animation: radar 2s infinite linear;
}
.loader-radar:after {
  left: 62%;
  top: 34%;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: currentColor;
  animation: radarDotGlow 2s infinite linear,
    radarDotMove 15s infinite cubic-bezier(0.5, -0.35, 0.5, 1.35);
}

@keyframes radar {
  to {
    transform: rotate(359deg);
  }
}

@keyframes radarDotGlow {
  0%,
  59.9%,
  87%,
  100% {
    color: rgba(25, 166, 207, 0.33);
    box-shadow: 0 0 0.1em 0.05em currentColor;
  }
  62% {
    color: #19a6cf;
    box-shadow: 0 0 0.1em 0.1em currentColor;
  }
}

@keyframes radarDotMove {
  0% {
    top: 0%;
    left: 85%;
  }
  40% {
    top: 30%;
    left: 70%;
  }
  100% {
    top: 50%;
    left: 50%;
  }
} */
