.createmap-base {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.createmap-tabContainer {
  padding: 20px 50px;
  border-radius: 30px;
  background-color: #fff;
  width: 40%;
  height: 100%;
  overflow-y: auto;
}
.createmap-tabContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.createmap-tabContainer::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

.createmap-demoContainer {
  display: flex;
  justify-content: center;
  width: 60%;
  height: 95%;
  background: var(--primary-color-light);
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.createmap-header {
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  color: var(--title-color);
}
.createmap-header img {
  height: 10%;
  width: 35%;
}

.createmap-tabs {
  display: flex;
  margin-bottom: 20px;
}
.createmap-tab {
  flex: 1;
  padding: 12px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}

.createmap-inputContainer {
  position: relative;
  margin-bottom: 20px;
}
.createmap-inputContainer-split {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 2%;
}
.createmap-label {
  display: block;
  font-weight: 600;
  margin-bottom: 7px;
  color: var(--label-color);
}
.createmap-input,
.createmap-selectBox {
  display: block;
  width: 100%;
  flex: 1;
  position: relative;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 12px 10px;
}

.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
}
.select-wrapper::after {
  position: absolute;
  content: "›";
  padding-top: 10px;
  font-size: 20px;
  transform: rotate(90deg);
  right: 0;
  z-index: 0;
}
.createmap-selectBox {
  text-align: center;
  appearance: none;
}

.createmap-selectBox > .optionGroup {
  text-align: center;
  font-weight: 600;
  font-style: italic;
  background-color: #ccc;
}

.createmap-input:hover,
.createmap-selectBox:hover {
  background-color: var(--primary-color-light);
}

.uppercase {
  text-transform: uppercase;
}

.createmap-input:focus,
.createmap-selectBox:focus {
  outline: none;
  border-color: var(--primary-color);
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}
.createmap-selectBox {
  text-transform: capitalize;
}
.createmap-selectBox > option {
  background-color: #fff;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}
.createmap-selectBox > option:checked {
  background-color: var(--primary-color-light);
}

.createmap-btnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.createmap-btnContainer > button:first-child {
  margin-right: 10px;
}

.createmap-mapLoaderContainer {
  width: 90%;
  max-width: 455px;
  display: flex;
  justify-content: center;
  background: #fff;
  padding-top: 65px;
}

.createmap-theme-option {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 12px 10px;
  flex-wrap: wrap;
  align-items: center;
}

.createmap-theme-option > p {
  font-size: 18px;
}

.createmap-theme-selectBox {
  text-transform: capitalize;
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  font-size: 14px;
  padding: 10px 12px;
  outline: none;
  color: var(--primary-color);
  width: 100%;
  max-width: 180px;
  appearance: none;
}
.createmap-slider {
  margin-top: 8px;
  display: block;
  width: 100%;
}
.createmap-theme-selectBox > option:checked {
  background: var(--primary-color-light);
  color: var(--primary-color);
}
.createmap-theme-selectBox > option:hover {
  background: var(--primary-color-light);
  color: var(--primary-color);
}

.createmap-addToCartContainer-mobile {
  display: none;
}

.createmap-accordion-base {
  margin: 10px 0;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}
@media (max-width: 768px) {
  .createmap-addToCartContainer-mobile {
    display: block;
  }
  .createmap-addToCartContainer-desktop {
    display: none;
  }
  .createmap-base {
    flex-direction: column-reverse;
    overflow-y: auto;
    height: auto;
  }
  .createmap-tabContainer {
    width: 100%;
    height: auto;
  }

  .createmap-demoContainer {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-right-radius: 20px;
    flex: unset;
  }

  .createmap-demoContainer > img {
    width: 98%;
    /* height: fit-content; */
  }

  .createmap-tabContainer {
    padding: 20px;
  }
}

.createmap-inputContainer-splite {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.createmap-inputContainer-splite > * {
  margin-right: 2%;
}
.createmap-inputContainer-splite > *:last-child {
  margin-right: 0;
}

.createmap-imageThumbnailContainer {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.createmap-themeOptions {
  width: 100px;
  height: 146px;
  border: 3px solid #bababa;
  padding: 1px;
  margin: 0 2px 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.createmap-aspectRatioFrameContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.createmap-aspectRatioFrameContainer > *:not(:last-child) {
  margin-right: 5px;
}
.createmap-aspectRatioFrame {
  border: 3px solid #ccc;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.createmap-inputComponentContainer {
  display: block;
  width: 100%;
  flex: 1;
  position: relative;
}
