.static-component{
    width: 100%;
    height: 100%;
    

}
.static-component section {
    height: 100%;
    
    
}

.static-component-para {
    padding-top: 10px;
    font-size: 12px;
}
.static-component-para a{
    color: blue;
    text-decoration: underline; 
}
.body {
    min-height: calc(100vh - 100px);
    max-width: 1024px;
    margin: auto;
    background-color: #f9f9f9;
    box-shadow: 0px 1px 8px 0px #d9d9d9;
    margin-top: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px 30px;
    
   
}

.body h1{
    padding-bottom: 10px;

}
.body h3{
    padding-top: 10px;
}