.sliderWithToolTip-base {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 30%);
  cursor: pointer;
}

.slider-toolTip {
  position: absolute;
  top: 50%;
  font-size: 50%;
  font-weight: 900;
  top: -10px;
  text-align: left;
  white-space: nowrap;
  pointer-events: none;
  box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 30%);
  border-radius: 5px;
  padding: 2px;
}
