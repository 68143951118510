.switch input {
  position: absolute;
  opacity: 0;
}

.switch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 20px;
  width: 40px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.switch div {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  transition: all 300ms;
}

.switch input:checked + div {
  transform: translate3d(100%, 0, 0);
}
