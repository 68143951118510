.footer-base {
  background-color: black;
  text-align: center;
  color: white;
  height: auto;
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  padding: 60px 80px;
}

.footer-listItem {
  padding: 10px;
}

.left-footer-listItem img {
  width: 180px;
}
.left-footer-listItem {
  flex-direction: column;
  text-align: left;
}
.right-footer-listItem {
  flex-direction: column;
  text-align: left;
}
.center-footer-listItem {
  flex-direction: column;
  text-align: left;
}
.footer-listItem a:hover {
  color: gray;
}
.right-footer-listItem img {
  width: 30px;
  margin: 5px;

}
.right-footer-listItem img:hover {
  opacity: 0.5;
}


@media only screen and (max-width: 1000px) {
  .footer-base {
    height: auto;
    flex-direction: column-reverse;
  }
  .footer-listItem {
    padding: 5px;
  }

  .center-footer-listItem {
    text-align: center;
  }
  .right-footer-listItem {
    text-align: center;
  }
  .left-footer-listItem {
    padding-top: 10px;
    text-align: center;
  }
}
