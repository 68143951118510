.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion.active:after {
  content: "\2212";
}

.panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out;
}

.panel.active {
  padding: 10px 15px;
  max-height: max-content;
  /* overflow: hidden; */
  transition: max-height 2s ease-out;
}
