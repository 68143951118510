* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  /* --image-background: #f5f5f9; */
  /* --primary-color: #5f30e2; */
  /* --primary-color-light: #f5f1ff; */
  /* --hover-background: #521beb; */
  --primary-color: #000;
  --primary-color-light: #efefef;
  --hover-background: #1c1c1c;
  --active-border-color: #c4b2f3;
  --light-border: #fafafb;
  --error-color: #e87d86;
  --error-background: #fef1f2;
  --title-color: #020447;
  --label-color: #676893;
}
