.homeHeader-base {
  width: 100%;
  display: flex;
  justify-self: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: var(--primary-color-light);
}

.homeHeader-logo {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;
}

.homeHeader-logo img {
  width: 100%;
}
.homeHeader-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeHeader-listItem {
  list-style: none;
  padding: 30px;
  color: black;
}
.homeHeader-listItem a {
  text-decoration: none;
  color: black;
  font-size: 20px;
}
.homeHeader-listItem a:hover {
  text-decoration: none;
  color: grey;
}
.homeHeader-button {
  justify-content: right;
  align-items: right;
}

.homeHeader-buttonElement {
  background: black;
  color: #fff;
  border: none;
  position: relative;
  height: 45px;
  font-size: 20px;
  padding: 0 10px;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;
}
.homeHeader-buttonElement:hover {
  background: #fff;
  color: black;
}
.homeHeader-buttonElement:before,
.homeHeader-buttonElement:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: black;
  transition: 400ms ease all;
}
.homeHeader-buttonElement:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}
.homeHeader-buttonElement:hover:before,
.homeHeader-buttonElement:hover:after {
  width: 100%;
  transition: 800ms ease all;
}
